body {
    background: #222;
    min-height: 100vh;
    color: #fff;
    box-sizing: border-box;
    padding: 30px;
    font-size: 12px;
    line-height: 1.35;
    font-family: Roboto, Helvetica, sans-serif;
    font-weight: 300;
  }
  
h1, h2, h3, h4, h5, h6 {
    color: white;
    margin-bottom: 0;
}

.st-title {
    font-size: medium;
    font-weight: bold;
}

.st-subtitle {
    font-size: small;
}

.st-panel {
    padding: 100px;
}

.st-panel-mini {
    padding: 25px;
}

.st-scene {
    font-weight: bold;
    font-size: larger;
}

.st-actor {
    font-weight: bold;
    font-size: large;
}

.st-emotion {
    font-size: small;
    font-style: italic;
}

.st-line-panel {
    margin: 50px;
    background-color: #333;
    border: 2px solid gray;
    padding: 25px;
}

.st-line {
    font-size: xx-large;
    word-wrap: normal;
}

.st-status-panel-right {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: #333;
    padding: 10px;
    border-radius: 4px;
}

.st-status-panel-left {
    position: absolute;
    top: 10px;
    left: 10px;
    background-color: #333;
    padding: 10px;
    border-radius: 4px;
}

.st-status-panel-label {
    font-size: large;
}

.st-badge-connecting, .st-badge-connected, .st-badge-disconnected {
    border-radius: 2px;
    font-size: smaller;
}

.st-badge-connecting {
    background-color: darkgoldenrod;
}

.st-badge-connected {
    background-color: green;
}

.st-badge-disconnected {
    background-color: darkred;
}

.st-tabbar {
    margin-top: 4em;
}
  
.st-tabbar.uk-tab > .uk-active > a {
    color: white;
}

.st-device-label {
    font-size: small;
    margin-top: 10px;
}

.st-progress {
    margin: 5px;
}

.st-button {
    background-color: #333;
    border-width: 0;
    font-size: xx-small;
}

.st-top-buttons-pannel {
    margin: 20px 0px;
}

.st-mic-on, .st-mic-off, .st-mic-status-on, .st-mic-status-off {
    text-align: center;
}

.st-mic-on, .st-mic-status-on {
    color: lime;
}

.st-mic-off, .st-mic-status-off {
    color: #555;
}

.st-mic-on:hover {
    color: yellow;
    cursor: hand;
}

.st-mic-off:hover {
    color: yellow;
    cursor: hand;
}

.st-icon-ok, .st-icon-warn, .st-icon-bad {
    font-size: small;
    margin: 2px;
}

.st-icon-ok {
    color: white;
}

.st-icon-warn {
    color: yellow;
}

.st-icon-bad {
    color: red;
}

.st-user-icon{
    font-size:20px !important;
    margin-left:5px;
}

.st-participant-icon{
    font-size:12px !important;
    margin-left:5px;
}

.st-user-inline{
    display:inline-flex; 
    line-height:20px;
    font-weight: bold;
}

.st-user-logout{
    color:white; 
    cursor:pointer;
}

.st-hidden {
    display: none;
}

.st-alert-message {
    font-size: large;
    word-wrap: normal;
}

.st-server-version {
    font-size: xx-small;
}

.st-recordingsession-panel {
    margin:0;
    border: 1px solid white;
    width: 200px;
    height: fit-content;
}

.st-recordingsession-panel-user {
    margin: auto;
    padding: 10px;
    background-color: #444;
    border-bottom: 1px solid white;
}

.st-recordingsession-panel-participantlist {
    height: 55vh;
}

.st-recordingsession-participant {
    padding: 4px 10px;
}

.st-main-panel {
    margin:auto;
    width:fit-content;
}

.st-recordingsession-name {
    font-weight: bold;
    border-bottom: 1px solid white;
    background-color: #333;
    padding: 10px;
}

.st-recordingsession-server {
    border-bottom: 1px solid white;
    background-color: #100;
    padding: 10px;
}

.st-recordingsession-server-label {
    display:inline-flex; 
    font-size: small;
}

.st-recordingsession-action-buttons {
    border-top: 1px solid white;
    margin: 0;
}

.st-buffering-info-panel {
    margin:20px 0px 0px 0px;
}

.shadow {
    -webkit-box-shadow: 0px 0px 3px 3px #444;
    -moz-box-shadow:    0px 0px 3px 3px #444;
    box-shadow:         0px 0px 3px 3px #444;
}

.st-output-console {
    margin-top: 10px;
    background-color: #444
}

.st-browser-limitation {
    background-color: #333;
    margin-top: 10px;
    padding: 3px;
}

.st-browser-limitation-title {
    font-weight: bold;
}